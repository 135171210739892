import React from 'react'
import styled from 'styled-components'

const Main = styled.main`
  max-width: 640px;
  margin: 0 auto;
  padding: 2.4rem;
`

const Container = ({children}) => {
  return <Main>{children}</Main>
}

export default Container

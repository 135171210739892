import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'
import Heading from '../components/heading'

const StyledBasePortableText = styled.div`
  max-width: 640px;
  margin: 0 auto;
  b{
    color: ${colors.lightBlack};
  }
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const FileDownload = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const SummerDayCamp2020 = props => {
  return (
    <>
      <Heading ja={'サマースクール2020\nデイキャンプ'} en='Daycamp Summer 2020' />
      <StyledBasePortableText>
        <p>デイキャンプのお申し込みは終了いたしました。<br />
          オンラインサマーキャンプ第2タームは引き続きお申し込み受付中です。
        </p>
        <StyledLink to='/summer-2020/term2/'>第2ターム申込へ</StyledLink>
      </StyledBasePortableText>
    </>
  )
}
export default SummerDayCamp2020

import React from 'react'
import GraphQLErrorList from '../../components/graphql-error-list'

import Container from '../../components/container'
import Seo from '../../components/seo'
import Daycamp from '../../components/summer-day-camp-2020-closed'
import Layout from '../../containers/layout'

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Daycamp />
      </Container>
    </Layout>
  )
}

export default IndexPage

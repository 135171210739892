import React from 'react'
import styled from 'styled-components'
import {colors, font, fontfamily} from '../lib/variables'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: ${props => !props.secondary ? '0 2.4rem  4rem 2.4rem' : '8rem 2.4rem 0rem 2.4rem'};
  @media (min-width: 1024px) {
    margin: ${props => !props.secondary ? '4rem 2.4rem  4rem 2.4rem' : '12rem 2.4rem 0rem 2.4rem'};
    padding: 4rem 0;
  }
`

const Title = styled.h1`
  font-family: ${fontfamily.jaRounded};
  color: ${colors.lightBlack};
  font-size: 2.2rem;
  font-weight: 700;
  white-space: pre-line;
  @media (min-width: 1024px) {
    font-size: 2.8rem;
  }
`

const SubTitle = styled.span`
  color: ${colors.green};
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  white-space: pre-line;
  @media (min-width: 1024px) {
    font-size: 1.6rem;
  }
`

const Heading = props => {
  const {ja, en, secondary} = props
  return (
    <Wrapper secondary={secondary}>
      {en && <SubTitle>{en}</SubTitle>}
      {ja && <Title>{ja}</Title>}
    </Wrapper>
  )
}
export default Heading
